import { Box, Button, TextField, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { useState } from "react";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Subscribe = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "subscribe", ...{ name, email } }),
    })
      .then(() => navigate("/thanks/"))
      .catch((error) => alert(error));
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  return (
    <div>
      <section id="subscribe" className="p-6 flex flex-col items-center">
        <div className="flex flex-col justify-center items-center sm:w-full m-auto gap-2 pb-10 px-1">
          <Typography
            className="text-6xl text-center"
            fontFamily={"Billy Ohio Dua"}
          >
            Sign up for my newsletter
          </Typography>
          <Typography variant="body1" className="text-center max-w-[550px]">
            New subscribers receive <em>My Unfurling</em> bonus chapters and the{" "}
            <em>Yes, You Can</em> self-publishing workbook. You'll also get
            sneak peeks into upcoming projects and other fun content delivered
            right to your inbox.
          </Typography>
        </div>
        <iframe
          src="https://lisamaybennett.substack.com/embed"
          width="480"
          height="320"
          style={{
            border: "11px solid #EEE",
            background: "white",
          }}
        ></iframe>
      </section>
    </div>
  );
};

export default Subscribe;
