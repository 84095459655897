import React from "react";
import { Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AirlineSeatReclineExtra } from "@mui/icons-material";

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      file(relativePath: { eq: "lisa_bennett.webp" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <section className="p-14">
        <div
          id="about"
          className="flex flex-wrap md:flex-nowrap justify-center items-center gap-20"
        >
          <div style={{ width: 300, minWidth: 200 }}>
            <Img
              style={{ transform: "scaleX(-1)" }}
              fluid={data.file.childImageSharp.fluid}
              alt="Lisa May Bennett"
            />
          </div>
          <div style={{ maxWidth: 750 }}>
            <div>
              <div className="text-center pb-6">
                <Typography className="text-6xl" fontFamily={"Billy Ohio Dua"}>
                  Lisa May Bennett
                </Typography>
              </div>
              <Typography className="pb-3" variant="body1">
                Lisa May Bennett is the author of the memoir{" "}
                <em>
                  My Unfurling: Emerging from the Grip of Anxiety, Self-Doubt,
                  and Drinking
                </em>
                . She blogs about balancing personal growth with self-acceptance
                at{" "}
                <a
                  className="pr-1 text-blue-700"
                  href="https://substack.com/@lisamaybennett"
                  target="_blank"
                >
                  Active Voice
                </a>
                and enjoys speaking about self-publishing.
              </Typography>
              <Typography className="pb-3">
                Lisa has worked for more than two decades in communications and
                marketing with a specialty in print collateral. She majored in
                creative writing in college, and after years of writing for
                employers, she made her dream of becoming a published author
                come true. Through the process of self-publishing her first
                book, Lisa discovered a passion for helping others share their
                stories. She hopes that her latest book,{" "}
                <em>
                  Yes, You Can Become an Author: Writing, Self-Publishing, and
                  Marketing Your First Book{" "}
                </em>
                will encourage would-be authors to take that leap.
              </Typography>
              <Typography className="pb-3">
                Lisa resides in Maryland with her husband, two cats, and a dog.
                She enjoys trying new things, spending time in nature, and
                dancing in the kitchen. She plans to cross the country in an RV
                with her husband one day and, of course, write a book about it.
              </Typography>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
